var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view" }, [
    !_vm.apiCallMade || _vm.requestError
      ? _c(
          "section",
          { staticClass: "section section--fullScreen section--flexCenter" },
          [
            _c(
              "div",
              { staticClass: "container container--xs" },
              [
                _c("ValidationObserver", {
                  ref: "form",
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var handleSubmit = ref.handleSubmit
                          var invalid = ref.invalid
                          return [
                            _c(
                              "form",
                              {
                                staticClass: "form",
                                attrs: { autocomplete: "off", name: "vault" },
                              },
                              [
                                _c("div", { staticClass: "form__title" }, [
                                  _c("h2", { staticClass: "h2" }, [
                                    _vm._v("Access Private Vault"),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "formGroup" },
                                  [
                                    _c("ValidationProvider", {
                                      attrs: {
                                        name: "email",
                                        rules: { required: true },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.password,
                                                      expression: "password",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "formInput formInput--fullWidth",
                                                  class: {
                                                    hasError: errors.length > 0,
                                                  },
                                                  attrs: {
                                                    name: "password",
                                                    type: "text",
                                                    placeholder:
                                                      "Password of private vault",
                                                    required: "",
                                                  },
                                                  domProps: {
                                                    value: _vm.password,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.password =
                                                        $event.target.value
                                                    },
                                                  },
                                                }),
                                                _c(
                                                  "p",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: errors[0],
                                                        expression: "errors[0]",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "formInput__hint formInput__hint--error",
                                                  },
                                                  [_vm._v("Name is required")]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "form__submit" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "btn btn--fullWidth btn--secondary",
                                      class: { isDisabled: invalid },
                                      attrs: { type: "submit" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          handleSubmit(
                                            _vm.updateBeatsTable(null, 0)
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Open")]
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    935067185
                  ),
                }),
              ],
              1
            ),
          ]
        )
      : _c("section", { staticClass: "section" }, [
          _vm.beats.length === 0
            ? _c("div", { staticClass: "container" }, [
                _c("h1", [_vm._v("No beats")]),
              ])
            : _c(
                "div",
                { staticClass: "container" },
                [
                  _c(
                    "h1",
                    { staticClass: "h1 h1--eliteTitles h-textCenter h-mb-60" },
                    [_vm._v("Private Vault Beats")]
                  ),
                  _c("PBeatTable", {
                    attrs: { beatPlaylist: _vm.beats },
                    on: {
                      fetchMoreData: function ($event) {
                        return _vm.updateBeatsTable($event, _vm.beats.length)
                      },
                    },
                  }),
                ],
                1
              ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }